export default {
    beforeMount(el) {
        const dataSrc = el.getAttribute('data-src');
        el.setAttribute('src', '');

        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    el.setAttribute('src', dataSrc);
                    observer.unobserve(el);
                }
            });
        });

        observer.observe(el);
    }
};