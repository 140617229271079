import axios from 'axios'
import UIkit from "uikit";

export default {
    beforeMount(el, binding, vnode) {
        el.addEventListener('click', event => {
            event.preventDefault();

            axios.get(el.href)
                .then((response) => {
                    UIkit.notification(binding.value.success, 'success');
                })
                .catch((error) => {
                    UIkit.notification(binding.value.fail, 'danger');
                });
        });
    },
    unmounted(el) {
        el.removeEventListener('click'); // Cleanup when the directive is unmounted
    }
};