export const isNullOrUndefined = (...values) => {
    return values.every(value => {
        return value === null || value === undefined;
    });
};

export const parseSelector = (selector) => {
    let rule = null;
    if (includes(selector, ':')) {
        rule = selector.split(':').pop();
        selector = selector.replace(`:${rule}`, '');
    }

    if (selector[0] === '#') {
        return {
            id: selector.slice(1),
            rule,
            name: null,
            scope: null
        };
    }

    let scope = null;
    let name = selector;
    if (includes(selector, '.')) {
        const parts = selector.split('.');
        scope = parts[0];
        name = parts.slice(1).join('.');
    }

    return {
        id: null,
        scope,
        name,
        rule
    };
};

export const includes = (collection, item) => {
    return collection.indexOf(item) !== -1;
};

export default class ErrorBag {
    items = [];

    first (field, scope = null) {
        const selector = isNullOrUndefined(scope) ? field : `${scope}.${field}`;
        const match = this._match(selector);

        return match && match.msg;
    }

    has (field, scope = null) {
        return !!this.first(field, scope);
    }

    _makeCandidateFilters (selector) {
        let matchesRule = () => true;
        let matchesScope = () => true;
        let matchesName = () => true;
        let matchesVM = () => true;

        const { id, rule, scope, name } = parseSelector(selector);

        if (rule) {
            matchesRule = (item) => item.rule === rule;
        }

        // match by id, can be combined with rule selection.
        if (id) {
            return {
                isPrimary: item => matchesRule(item) && (item => id === item.id),
                isAlt: () => false
            };
        }

        if (isNullOrUndefined(scope)) {
            // if no scope specified, make sure the found error has no scope.
            matchesScope = item => isNullOrUndefined(item.scope);
        } else {
            matchesScope = item => item.scope === scope;
        }

        if (!isNullOrUndefined(name) && name !== '*') {
            matchesName = item => item.field === name;
        }

        if (!isNullOrUndefined(this.vmId)) {
            matchesVM = (item) => item.vmId === this.vmId;
        }

        // matches the first candidate.
        const isPrimary = (item) => {
            return matchesVM(item) && matchesName(item) && matchesRule(item) && matchesScope(item);
        };

        // matches a second candidate, which is a field with a name containing the '.' character.
        const isAlt = (item) => {
            return matchesVM(item) && matchesRule(item) && item.field === `${scope}.${name}`;
        };

        return {
            isPrimary,
            isAlt
        };
    }

    _match (selector) {
        if (isNullOrUndefined(selector)) {
            return undefined;
        }

        const { isPrimary, isAlt } = this._makeCandidateFilters(selector);

        return this.items.reduce((prev, item, idx, arr) => {
            const isLast = idx === arr.length - 1;
            if (prev.primary) {
                return isLast ? prev.primary : prev;
            }

            if (isPrimary(item)) {
                prev.primary = item;
            }

            if (isAlt(item)) {
                prev.alt = item;
            }

            // keep going.
            if (!isLast) {
                return prev;
            }

            return prev.primary || prev.alt;
        }, {});
    };
}

