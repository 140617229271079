export default {
    mounted: function (el, binding, vnode) {
        // save
        el.addEventListener('input', event => localStorage.setItem(getKey(el, binding), el.value));
        // el.addEventListener('change', event => localStorage.setItem(getKey(el, binding), el.value));

        $(el).on('select2:select', function (e) {
            localStorage.setItem(getKey(el, binding), $(el).val());
        });

        // load
        if (localStorage.getItem(getKey(el, binding)) && (el.value == null || el.value == '' || el.value == -1)) {
            el.value = localStorage.getItem(getKey(el, binding));
            el.className += ' remembered';
            el.dispatchEvent(new Event('input'));
        }

        el.addEventListener('submit', (event) => {
            if (binding.hasOwnProperty("value") && binding.value.hasOwnProperty("permanent")) {

            } else {
                localStorage.removeItem(getKey(el, binding));
            }
        });

        // clear
        // vnode.context.$on('formSubmitted', () => {
        //     if (binding.hasOwnProperty("value") && binding.value.hasOwnProperty("permanent")) {
        //
        //     } else {
        //         localStorage.removeItem(getKey(el, binding));
        //     }
        //
        // });
    }
}

function getKey(el, binding) {
    let key = el.name;

    try {
        if (binding.hasOwnProperty("value") && binding.value.hasOwnProperty("id")) {
            key += '[' + (binding.value.id) + ']';
        }

        if (binding.hasOwnProperty("value") && binding.value.hasOwnProperty("locale")) {
            key += '[' + (binding.value.locale) + ']';
        }
    } catch (e) {
        
    }

    return key;
}